import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';


import { ISlidersTableData } from './LibrarySliderPage.interfaces';


const SLIDER_TABLE_COLUMNS: ReadonlyArray<Column<ISlidersTableData>> = [

  {
    accessor: 'title',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Name</div>,
    width: '95%%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick } }) => (
      <SettingsButton supportiveText="Update tags" onClick={() => onClick(id)} />
    ),
    Header: () => <span className="h-hide-visually">Settings</span>,
    width: '5%',
  },
];

export { SLIDER_TABLE_COLUMNS };
