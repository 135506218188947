import { gql } from '@apollo/client';

const GET_SLIDER_DETAILS = gql`
  query getSliderDetails($sliderId: Int!) {
    slider {
      getSlider(sliderId: $sliderId) {
        animation
        arrows
        autoplay
        autoplaySpeed
        description
        dots
        id
        infinite
        sliderItems {
          id
          asset {
            assetId
            presignedUrl
          }
          author
          quote
          text
          type
        }
        slidesToScroll
        slidesToShow
        speed
        title
        type
      }
    }
  }
`;
export { GET_SLIDER_DETAILS };
