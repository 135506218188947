import React, { useState } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  Button,
  ButtonIconOnly,
  Dialog,
  FlexContainer,
  InputFieldWrapper,
  PlusIcon,
  SidebarDialog,
  Spacing,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { IEditableSliderItem } from 'interfaces';
import { Controller } from 'react-hook-form';

import { SliderItemsOverview, UpsertSliderItem } from './Components';
import { SliderItemsTabProps } from './SliderItemsTab.interfaces';


const SliderItemsTab = ({
  control,
  onPreviewClick,
  getValues,
  setValue,
  projectId,
}: SliderItemsTabProps) => {
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const [deleteSliderItemId, setDeleteSliderItemId] = useState<string>();
  const [isDeleteSliderItemDialogOpen, setIsDeleteSliderItemDialogOpen] = useState<boolean>(false);
  const [isUpsertMode, setIsUpsertMode] = useState<boolean>(false);
  const [selectedSliderItem, setSelectedSliderItem] = useState<IEditableSliderItem>();

  const handleUpsertSliderItem = (selectedId?: string) => {
    const { sliderItems = []} = getValues()

    setSelectedSliderItem((sliderItems as IEditableSliderItem[]).find(({ tempId }) => tempId === selectedId));
    setIsUpsertMode(true);
  };

  const handleCloseUpsertSliderItem = () => {
    setSelectedSliderItem(undefined);
    setIsUpsertMode(false);
  };

  const handleCancelDeleteSliderItem = () => {
    setIsDeleteSliderItemDialogOpen(false);
    setDeleteSliderItemId(undefined);
  };

  const handleSaveSliderItem = (item: IEditableSliderItem) => {
    const { sliderItems } = getValues();

    const isUpdate = (sliderItems as IEditableSliderItem[]).some(({ tempId }) => tempId === item.tempId);

    if (isUpdate) {
      setValue('sliderItems', (sliderItems as IEditableSliderItem[]).map((sliderItem) => {
        if (sliderItem.tempId === item.tempId) return item;

        return sliderItem;
      }))
    } else {
      setValue('sliderItems', [...sliderItems, item]);
    }

    handleCloseUpsertSliderItem();
  }

  const handleDeleteSliderItem = (selectedId: string) => {
    const { sliderItems = [], sliderItemsToDelete = [] } = getValues();
    const itemToDelete = (sliderItems as IEditableSliderItem[]).find(({ tempId }) => tempId === selectedId);

    if (itemToDelete) {
      setValue('sliderItems', (sliderItems as IEditableSliderItem[]).filter(({ tempId }) => tempId !== selectedId));
      if (itemToDelete.id) setValue('sliderItemsToDelete', [...sliderItemsToDelete, itemToDelete.id]);
    }

    handleCancelDeleteSliderItem();
  };

  return (
    <SidebarContainer hasPaddingEnds>
      <Spacing size="6x-large">
        <Controller
          control={control}
          name="sliderItems"
          render={({ field, fieldState }) => (
            <>
              <div className="c-slider-items-overview">
                <InputFieldWrapper
                  id="id_slider_items"
                  label="Slider items"
                  message={{error: getFormFieldErrorMessage(fieldState), success: ''}}
                  type="custom"
                  isLabelHidden
                >
                  <SliderItemsOverview
                    sliderItems={field.value}
                    onDeleteClick={(tempId: string) => {
                      setIsDeleteSliderItemDialogOpen(true);
                      setDeleteSliderItemId(tempId);
                    }}
                    onEditClick={handleUpsertSliderItem}
                  />
                </InputFieldWrapper>
              </div>
              <Spacing size="x-large">
              <InputFieldWrapper
                id="id_add_slider_item"
                label="Add slider item"
                labelType="span"
                type="custom"
                isLabelSideBySide
              >
                <FlexContainer justifyContent="flex-end" isFullWidth>
                  <ButtonIconOnly icon={PlusIcon} text="Add slider item" onClick={() => handleUpsertSliderItem(undefined)} />
                </FlexContainer>
              </InputFieldWrapper>
            </Spacing>
            </>
          )}
        />
      </Spacing>

      <FlexContainer justifyContent="flex-end">
        <Button size="xs" text="Preview" type="button" variant="primary" onClick={onPreviewClick} />
      </FlexContainer>

      <Dialog
        isOpen={isDeleteSliderItemDialogOpen}
        title="Delete slide?"
        isNarrow
        onCancel={handleCancelDeleteSliderItem}
        onClose={handleCancelDeleteSliderItem}
        onConfirm={() => handleDeleteSliderItem(String(deleteSliderItemId))}
      />

      <SidebarDialog
        isOpen={isUpsertMode}
        onClose={handleCloseUpsertSliderItem}
      >
        {isUpsertMode && (
          <UpsertSliderItem
            projectId={projectId}
            sliderItem={selectedSliderItem}
            onCancel={handleCloseUpsertSliderItem}
            onSave={handleSaveSliderItem}
          />
        )}
      </SidebarDialog>
    </SidebarContainer>
  );
};

export { SliderItemsTab };
