import { useState, useEffect, useContext } from 'react';

import { IDBSlider } from '@netfront/ekardo-content-library';
import { TablePageTemplate, LibrarySliderSidebarView } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetAllSliders, useToast } from 'hooks';
import { useRouter } from 'next/router';

import { SLIDER_TABLE_COLUMNS } from './LibrarySliderPage.constants';
import { getFormsTableData } from './LibrarySliderPage.helpers';
import { ISlidersTableData } from './LibrarySliderPage.interfaces';


const LibrarySliderPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId }} = useRouter();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [allSliderItems, setAllSliderItems] = useState<IDBSlider[]>();
  const [formsTableData, setSlidersTableData] = useState<ISlidersTableData[]>([]);
  const [projectName, setProjectName] = useState<string>('');
  const [selectedSliderId, setSelectedSliderId] = useState<number>();
  const [filter, setFilter] = useState<string>();


  const { handleGetAllSliders, isLoading: isGetAllSlidersLoading = false } = useGetAllSliders({
    onCompleted: ({ sliders }) => {

      setAllSliderItems(sliders);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const addNewFormItem = () => {
    setSelectedSliderId(undefined);
    setIsSideBarOpen(true);
  };


  const handleSelectItem = (id: number) => {
    setSelectedSliderId(id);
    setIsSideBarOpen(true);
  }

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setSelectedSliderId(undefined);
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handleUpdate = () => {
    handleCloseSidebar();
    void handleGetAllSliders({
      projectId: projectId,
      title: filter,
    });
  }


  useEffect(() => {
    if (!projectId) return;

    void handleGetAllSliders({
      projectId: projectId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, filter]);

  useEffect(() => {
    if (!allSliderItems) {
      return;
    }

    setSlidersTableData(
      getFormsTableData({
        sliderItems: allSliderItems,
        onSettingsButtonClick: (id) => {
          handleSelectItem(id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSliderItems]);

  useEffect(() => {
    if (!project) return;
    setProjectName(project.name);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <>
      <TablePageTemplate<ISlidersTableData>
        activePage="library"
        activeSubPage="slider"
        additionalBreadcrumbItems={[{
          key: '1',
          content: 'Library',
        }, {
          key: '2',
          content: 'Slider',
        }]}
        columns={SLIDER_TABLE_COLUMNS}
        data={formsTableData}
        defaultActiveTabId="id_general_tab"
        description={`Sliders for ${String(projectName)}`}
        handleAddNewClick={addNewFormItem}
        handleSearch={handleFilterSearch}
        informationBoxMessage={'Manage the project sliders'}
        isLoading={isGetAllSlidersLoading}
        logoUrl={project?.logo?.presignedUrl}
        pageTitle={projectName}
        searchPlaceholder="Search"
        size={project?.logo?.presignedUrl ? 'small': 'medium'}
        tableType="sliders"
        title={`${String(projectName)} sliders`}
        hideSideBarButtons
      />

      <LibrarySliderSidebarView
        handleSideBarClose={handleCloseSidebar}
        isSideBarOpen={isSideBarOpen}
        projectId={String(projectId)}
        selectedSliderId={selectedSliderId}
        onUpdate={handleUpdate}
      />
    </>

  );
};

export { LibrarySliderPage };
