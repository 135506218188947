/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBSlider } from "@netfront/ekardo-content-library"
import { FormFieldProps } from "@netfront/ui-library"
import { generateRandomId } from "utils";

const getSliderDefaultValues = (slider?: IDBSlider): FormFieldProps => {
  const {
    title = '',
    description = '',
    animation = 'LINEAR',
    arrows: hasArrows = true,
    autoplay: isAutoplay = false,
    autoplaySpeed = 3000,
    dots: hasDots = true,
    infinite: isInfinite = true,
    sliderItems,
    slidesToScroll = 1,
    slidesToShow = 1,
    speed = 3000,
    id,
  } = slider ?? {};
  return {
    id,
    title,
    description,
    animation: animation || 'LINEAR',
    hasArrows: hasArrows || true,
    isAutoplay: isAutoplay || false,
    autoplaySpeed: autoplaySpeed || 3000,
    hasDots: hasDots || true,
    isInfinite: isInfinite || true,
    sliderItems: sliderItems?.map(({ id: sliderItemId, asset, author = '', quote = '', text = '', type }) => ({
      tempId: generateRandomId(),
      id: sliderItemId,
      presignedUrl: asset ? asset.presignedUrl : undefined,
      assetId: asset ? asset.assetId: undefined,
      author,
      quote,
      text,
      type,
    })) || [],
    slidesToScroll: slidesToScroll || 1,
    slidesToShow: slidesToShow || 1,
    speed: speed || 3000,
    sliderItemsToDelete: [],
  };
};

const getSliderVariables = (item: FormFieldProps) => {
  const {
    animation,
    hasArrows = true,
    isAutoplay = false,
    hasDots = true,
    isInfinite = true,
    title,
    description,
    sliderItems = [],
    speed,
    sliderItemsToDelete = [],
    slidesToShow,
  } = item;

  return {
    animation,
    arrows: hasArrows,
    autoplay: isAutoplay,
    description,
    dots: hasDots,
    infinite: isInfinite,
    slidesToScroll: sliderItems.length,
    slidesToShow,
    speed,
    title,
    sliderItems,
    sliderItemsToDelete,
  }
};  

export { getSliderDefaultValues, getSliderVariables };
