import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_SLIDER_ITEM } from './useDeleteSliderItem.graphql';
import {
  IDeleteSliderItemMutationGraphQLResponse,
  IDeleteSliderItemMutationVariables,
  IHandleDeleteSliderItemParams,
  IUseDeleteSliderItemOptions,
  IUseDeleteSliderItem,
} from './useDeleteSliderItem.interfaces';

const useDeleteSliderItem = (options?: IUseDeleteSliderItemOptions): IUseDeleteSliderItem => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteSliderItemOptions);

  const [executeDeleteSliderItem, { loading: isLoading }] = useEkardoMutation<
  IDeleteSliderItemMutationGraphQLResponse,
  IDeleteSliderItemMutationVariables
  >({
    mutation: mutation ?? DELETE_SLIDER_ITEM,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          deleteSliderItem: isCompleted,
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteSliderItem = ({ sliderItemId }: IHandleDeleteSliderItemParams) => {
    void executeDeleteSliderItem({
      variables: {
        sliderItemId,
      },
    });
  };

  return {
    handleDeleteSliderItem,
    isLoading,
  };
};

export { useDeleteSliderItem };
