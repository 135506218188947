export const stripHtmlMarkup = (html?: string, type: DOMParserSupportedType = 'application/xml') => {
  if (!html) return undefined;
  const document = new DOMParser().parseFromString(html, type);
  const parserError = document.querySelector('parsererror');

  if (parserError) {
    return parserError.textContent;
  }

  return document.documentElement.textContent;
};
