import { gql } from '@apollo/client';

const GET_ALL_SLIDERS = gql`
  query getAllSliders($projectId: String!, $title: String) {
    slider {
      searchSliders(projectId: $projectId, title: $title) {
        id
        title
      }
    }
  }
`;
export { GET_ALL_SLIDERS };
