import React, { ReactNode } from 'react';

import { DBContentGroupTypeType } from '@netfront/ekardo-content-library';
import { Button, CloseIcon, DesktopIcon, EnterIcon } from '@netfront/ui-library';
import { ContentBuilderBetaToggle } from 'components';
import Link from 'next/link';

const ContentBuilderToolbar = ({
  additionalToolbarItems,
  contentGroupId,
  contentPageId,
  contentType,
  dashboardLink,
  handleTestMode,
  isTestMode = false,
}: {
  additionalToolbarItems?: ReactNode;
  contentGroupId?: number;
  contentPageId?: number;
  contentType?: DBContentGroupTypeType;
  dashboardLink: string;
  handleTestMode?: () => void;
  isTestMode?: boolean;
}) => {
  const viewModeUrl = window.location.href.replace(/\/edit$/, '');
  const previewUrl = `${viewModeUrl}/preview/${String(contentPageId)}`;

  return (
    <div className="c-content-builder-toolbar">
      <Link href={dashboardLink} passHref>
        <span className="c-content-builder-toolbar__dashboard-link">
          <span className="c-content-builder-toolbar__icon-container">
            <EnterIcon className="c-icon c-content-builder-toolbar__icon" />
          </span>
          <span className="c-content-builder-toolbar__text">Dashboard</span>
        </span>
      </Link>
      <div className="c-content-builder-toolbar__items">
        <div className="c-content-builder-toolbar__item">{additionalToolbarItems}</div>
        <div className="c-content-builder-toolbar__item">
          <ContentBuilderBetaToggle contentGroupId={contentGroupId} contentType={contentType} />
          {contentType === 'CARTOON' && handleTestMode && (
            <Button
              additionalClassNames={`c-content-builder__edit-button ${isTestMode ? 'is-active' : ''}`}
              icon={isTestMode ? CloseIcon : EnterIcon}
              size="xs"
              text={`${isTestMode ? 'Exit test' : 'Test'} mode`}
              type="button"
              variant="primary-inverse"
              onClick={handleTestMode}
            />
          )}
          <Button
            additionalClassNames={`c-content-builder__edit-button active`}
            icon={DesktopIcon}
            linkButton={{
              linkComponent: 'a',
              id: `id_preview_button`,
              url: previewUrl,
              target: '_blank',
            }}
            size="xs"
            text="Preview"
            type="button"
            variant="primary-inverse"
          />
        </div>
      </div>
    </div>
  );
};

export { ContentBuilderToolbar };
