
import { useRef } from 'react';

import { ApolloError } from '@apollo/client';
import { IDBSlider } from '@netfront/ekardo-content-library';
import { useCreateSlider, useCreateSliderItem, useDeleteSliderItem, useToast, useUpdateSlider, useUpdateSliderItem } from 'hooks';
import { IEditableSliderItem } from 'interfaces';



const useUpsertSlider = ({ onCompleted }: { onCompleted: ({ slider } : { slider: IDBSlider; } ) => void }) => {
  const sliderItemsRef = useRef<{ value: IEditableSliderItem[] | undefined}>({ value: undefined});

  const { handleToastError } = useToast();

  const handleGetError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const { handleUpdateSlider, isLoading: isUpdateSliderLoading = false } = useUpdateSlider({
    onCompleted,
    onError: handleGetError,
  });

  const { handleCreateSlider, isLoading: isCreateSliderLoading = false } = useCreateSlider({
    onCompleted: ({ slider }) => {
      const { id: sliderId } = slider;
      if (sliderItemsRef.current.value) {
        sliderItemsRef.current.value.forEach((item) => {
          const {
            assetId,
            author,
            quote,
            text,
            type: sliderItemType,
          } = item;
  
         
          handleCreateSliderItem({
            assetId,
            author,
            quote,
            sliderId,
            text,
            type: sliderItemType,
          });
        });
      }
      
      
      onCompleted({ slider });


    },
    onError: handleGetError,
  });


  const { handleUpdateSliderItem, isLoading: isUpdateSliderItemLoading = false } = useUpdateSliderItem({
    onError: handleGetError,
  });

  const { handleDeleteSliderItem, isLoading: isDeleteSliderItemLoading = false } = useDeleteSliderItem({
    onError: handleGetError,
  });
  
  const { handleCreateSliderItem, isLoading: isCreateSliderItemLoading = false } = useCreateSliderItem({
    onError: handleGetError,
  });

  const handleUpsertSlider = ({
    animation = 'LINEAR',
    arrows = true,
    autoplay  = false,
    autoplaySpeed = 3000,
    description,
    dots = true,
    height,
    infinite = true,
    projectId,
    sliderId,
    slidesToScroll = 1, 
    slidesToShow = 1,
    speed = 3000,
    title,
    type = 'FLEX',
    sliderItems = [],
    sliderItemsToDelete = [],
  }: {
    
    animation?: 'LINEAR' | 'FADE';
    arrows?: boolean;
    autoplay?: boolean;
    autoplaySpeed?: number;
    description?: string;
    dots?: boolean;
    height?: number;
    infinite?: boolean;
    projectId: string;
    sliderId?: number;
    sliderItems: IEditableSliderItem[];
    sliderItemsToDelete?: number[];
    slidesToScroll?: number;
    slidesToShow?: number;
    speed?: number;
    title: string;
    type?: 'FLEX'; 


  }) => {
    sliderItemsRef.current.value = sliderItems;

    if (!sliderId) {
      handleCreateSlider({
        animation,
        arrows,
        autoplay,
        autoplaySpeed,
        description,
        dots,
        height,
        infinite,
        projectId,
        slidesToScroll,
        slidesToShow,
        speed,
        title,
        type,
      });
    } else {

      sliderItems.forEach((item) => {
        const {
          id: sliderItemId,
          assetId,
          author,
          quote,
          text,
          type: sliderItemType,
        } = item;

        if (sliderItemId) {

          handleUpdateSliderItem({
            assetId,
            author,
            quote,
            sliderItemId,
            text,
            type: sliderItemType,
          });

        } else {
          handleCreateSliderItem({
            assetId,
            author,
            quote,
            sliderId,
            text,
            type: sliderItemType,
          });
        }
      });

      sliderItemsToDelete.forEach((id) => {
        handleDeleteSliderItem({
          sliderItemId: id,
        })
      });

      handleUpdateSlider({
        animation,
        arrows,
        autoplay,
        autoplaySpeed,
        description,
        dots,
        height,
        infinite,
        sliderId,
        slidesToScroll,
        slidesToShow,
        speed,
        title,
        type,
      });
    }
  };

  return {
    isLoading: isCreateSliderLoading || isUpdateSliderLoading || isCreateSliderItemLoading || isUpdateSliderItemLoading || isDeleteSliderItemLoading,
    handleUpsertSlider,
  };
};
export { useUpsertSlider };
