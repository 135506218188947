import { useEffect, useState } from 'react';

import { IContentPage } from '@netfront/ekardo-content-library';
import { Spinner } from '@netfront/ui-library';
import { ContentBuilderPreview } from 'components';
import { useGetContentPage, useToast } from 'hooks';
// import { createRoot } from 'react-dom/client';
import { handleGetFileText } from 'utils';

import { ContentBuilderPreviewPageProps } from './ContentBuilderPreview.interfaces';

const ContentBuilderPreviewPage = ({ contentPageId, projectId }: ContentBuilderPreviewPageProps) => {
  const { handleToastError } = useToast();
  const [contentPage, setContentPage] = useState<IContentPage>();
  const [customStyleSheet, setCustomStyleSheet] = useState<string>('');
  const { handleGetContentPage, isLoading: isGetContentPageLoading = false } = useGetContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentPage: returnedContentPage }) => {
      setContentPage(returnedContentPage);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!projectId) return;

    const getCustomCss = async () => {
      const customStyleSheetText = await handleGetFileText(
        `https://ekardo.s3.ap-southeast-2.amazonaws.com/${String(
          process.env.REACT_APP_CSS_FILE_ENVIRONMENT,
        )}/project/${projectId}/Css/allinone.min.css`,
      );
      setCustomStyleSheet(customStyleSheetText);
    };

    void getCustomCss();
  }, [projectId]);

  useEffect(() => {
    if (!contentPageId) return;

    void handleGetContentPage({
      contentPageId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPageId]);

  return (
    <>
      <Spinner isLoading={isGetContentPageLoading} />
      {contentPage && <ContentBuilderPreview contentPage={contentPage} customStyleSheet={customStyleSheet} isEditMode={false} />}
    </>
  );
};

export { ContentBuilderPreviewPage };
