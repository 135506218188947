import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_SLIDER_ITEM } from './useUpdateSliderItem.graphql';
import {
  IUpdateSliderItemMutationGraphQLResponse,
  IUpdateSliderItemMutationVariables,
  IHandleUpdateSliderItemParams,
  IUseUpdateSliderItemOptions,
  IUseUpdateSliderItem,
} from './useUpdateSliderItem.interfaces';

const useUpdateSliderItem = (options?: IUseUpdateSliderItemOptions): IUseUpdateSliderItem => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateSliderItemOptions);

  const [executeUpdateSliderItem, { loading: isLoading }] = useEkardoMutation<
  IUpdateSliderItemMutationGraphQLResponse,
  IUpdateSliderItemMutationVariables
  >({
    mutation: mutation ?? UPDATE_SLIDER_ITEM,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          slider: {
            updateSliderItem: sliderItem
          }
        } = data;

        void onCompleted({ sliderItem });
      },
      onError,
    },
    token,
  });

  const handleUpdateSliderItem = ({
    assetId,
    author,
    sliderItemId,
    quote,
    text,
    type,
  }: IHandleUpdateSliderItemParams) => {
    void executeUpdateSliderItem({
      variables: {
        sliderItem: {
          assetId,
          author,
          sliderItemId,
          quote,
          text,
          type,
        },
      },
    });
  };

  return {
    handleUpdateSliderItem,
    isLoading,
  };
};

export { useUpdateSliderItem };
