import { gql } from '@apollo/client';

const CREATE_SLIDER_MUTATION = gql`
  mutation CreateSlider($slider: CreateSliderInput!) {
    slider {
      createSlider(slider: $slider) {
        id
      }
    }
  }
`;

export { CREATE_SLIDER_MUTATION };
