/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useRef, useState } from 'react';

import { IDBAsset, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ISliderItem } from '@netfront/ekardo-content-library';
import { ControlledForm, FormFieldProps, Input, Select, SidebarButtons, Spacing, Spinner, Textarea, useControlledForm } from '@netfront/ui-library';
import { SidebarContainer, TextEditor, Uploader } from 'components';
import { useUpsertAssetWrapper } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import { createSyntheticEvent, generateRandomId } from 'utils';

import { UpsertSliderItemProps } from './UpsertSliderItem.interfaces';



const UpsertSliderItem = ({ onCancel, onSave, projectId, sliderItem }: UpsertSliderItemProps) => {

  const droppedFileRef = useRef<{value: File | undefined}>({ value: undefined });
  const hasDeletedOriginalImageRef = useRef<{value: boolean }>({ value: false });
  
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [type, setType] = useState<ISliderItem['type']>('QUOTE');
  
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    
  });

  const { handleUpsertAsset, isLoading: isUpsertAssetLoading = false } = useUpsertAssetWrapper();


  const handleDropFile = (uploadedFile?: File) => {
    droppedFileRef.current.value = uploadedFile;
  };

  const handleRemoveAsset = () => {
    hasDeletedOriginalImageRef.current.value = true;
  };

  const handleSave = (item: FormFieldProps) => {
    const { 
      author,
      quote,
      text,
      id,
      tempId,
      type: sliderType,
    } = item;


    handleUpsertAsset({
      assetType: 'image',
      isCreate: true,
      projectId: String(projectId),
      uploadedFile: droppedFileRef.current.value,
      deletedFileId: hasDeletedOriginalImageRef.current.value ? defaultValues?.assetId: undefined,
      isAnimatedImage: false,
      callBack: (imageAsset?: IDBAsset) => {

        reset();
        droppedFileRef.current.value = undefined;
        hasDeletedOriginalImageRef.current.value = false;

        onSave({
          author: type === 'QUOTE' ? author : '',
          quote: type === 'QUOTE' ? quote : '',
          text: type === 'CODE'? text : '',
          assetId: imageAsset?.assetId,
          presignedUrl: imageAsset?.presignedUrl,
          id,
          tempId,
          type: sliderType,
        });

      }
    });
  };

  

  useEffect(() => {
    const {
      author = '',
      quote = '',
      text = '',
      assetId,
      presignedUrl,
      id,
      tempId,
      type: sliderItemType = 'QUOTE',
    } = sliderItem ?? {};

    setDefaultValues({
      author,
      quote,
      text,
      assetId,
      presignedUrl,
      id,
      type: sliderItemType,
      tempId: tempId || generateRandomId(),
    });

    setType(sliderItemType)
  }, [sliderItem]);


  return (
    <SidebarContainer hasPaddingEnds>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isUpsertAssetLoading} />
        {defaultValues && (
          <>
            <Spacing size="large">
              <Controller
                control={control as Control<FormFieldProps>}
                name="type"
                render={({ field, fieldState }) => (
                  <Select
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_slider_item_Type"
                    labelText="Type"
                    options={[
                      {
                        id: 'QUOTE',
                        name: 'Quote',
                        value: 'QUOTE',
                      },
                      {
                        id: 'IMAGE',
                        name: 'Image',
                        value: 'IMAGE',
                      },
                      {
                        id: 'CODE',
                        name: 'Code',
                        value: 'CODE',
                      }
                    ]}
                    {...field}
                    isLabelSideBySide
                    isRequired
                    onChange={(event) => {
                      const { target: { value }} = event;
                      field.onChange(event);
                      setType(value as ISliderItem['type']);
                    }}
                  />
                )}
              />
            </Spacing>

            { type === 'QUOTE' && (
              <>
                <Spacing>
                  <Controller
                    control={control as Control<FormFieldProps>}
                    name="quote"
                    render={({ field }) => (
                      <Textarea
                        id="id_slider_item_quote"
                        labelText="Quote"
                        isLabelSideBySide
                        {...field}
                      />
                    )}
                  />
                </Spacing>
                <Spacing>
                  <Controller
                    control={control as Control<FormFieldProps>}
                    name="author"
                    render={({ field }) => (
                      <Input
                        id="id_slider_item_author"
                        labelText="Author"
                        tooltipText="Author of the quote"
                        type="text"
                        isLabelSideBySide
                        isRequired
                        {...field}
                      />
                    )}
                  />
                </Spacing>
              </>
            )}

            {type === 'IMAGE' && (
              <Spacing >
                <Controller
                  control={control as Control<FormFieldProps>}
                  name="presignedUrl"
                  render={({ field }) => (
                    <Uploader
                      assetType="image"
                      initialUrl={field.value}
                      isRequired={true}
                      labelText="Image"
                      name={field.name}
                      tooltipText="Upload your image"
                      isLabelSideBySide
                      onDelete={handleRemoveAsset}
                      onDrop={handleDropFile}
                    />
                  )}
                />
              </Spacing>
            )}

            {type === 'CODE' && (
              <Spacing>
                <Controller
                  control={control as Control<FormFieldProps>}
                  name="text"
                  render={({ field, fieldState }) => (
                    <TextEditor
                      errorMessage={getFormFieldErrorMessage(fieldState)}
                      id="id_slider_item_text"
                      initialValue={defaultValues.text ?? ''}
                      label="Code"
                      minHeight={175}
                      isRequired
                      onInputChange={(content: string) => {
                        field.onChange(createSyntheticEvent('text', content))
                      }}
                    />
                  )}
                />
              </Spacing>
            )}



            <SidebarButtons
              onCancel={onCancel}
              onSaveButtonType="submit"
            />
          </>
        )}
      </ControlledForm>
    </SidebarContainer>
  );
};

export { UpsertSliderItem };
