
import { ILoginOverviewTableData, ILoginOverviewTableDataParams } from "./LoginOverviewPage.interfaces";


const getLoginOverviewTableData = ({ items }: ILoginOverviewTableDataParams): ILoginOverviewTableData[] => {
  return items.map(({
    email = '',
    firstName = '',
    groupName = '',
    id,
    lastName = '',
    firstLogin = '',
    lastLogin = '',
    loginCount = 0,
   }) => {
    return {
      email,
      firstName,
      groupName,
      id,
      lastName,
      firstLogin,
      lastLogin,
      loginCount,
    };
  });
};

export { getLoginOverviewTableData };
