import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ALL_SLIDERS } from './useGetAllSliders.graphql';
import {
  IGetAllSlidersQueryGraphQLResponse,
  IGetAllSlidersQueryVariables,
  IUseGetAllSliders,
  IUseGetAllSlidersOptions,
} from './useGetAllSliders.interfaces';

const useGetAllSliders = (options?: IUseGetAllSlidersOptions): IUseGetAllSliders => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetAllSlidersOptions);

  const [executeGetAllSliders, { loading: isLoading }] = useEkardoLazyQuery<
    IGetAllSlidersQueryGraphQLResponse,
    IGetAllSlidersQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          slider: { searchSliders: sliders },
        } = data;

        onCompleted({
          sliders,
        });
      },
      onError,
    },
    query: GET_ALL_SLIDERS,
    token,
  });

  const handleGetAllSliders = async ({ projectId, title }: IGetAllSlidersQueryVariables) => {
    await executeGetAllSliders({
      variables: {
        projectId,
        title,
      },
    });
  };

  return {
    handleGetAllSliders,
    isLoading,
  };
};

export { useGetAllSliders };
