import { useContext, useEffect, useState } from 'react';

import {
  useBackofficeSiteUrls,
  useDomain,
  useIdentitySiteUrls,
  DBNetfrontServiceType,
} from '@netfront/gelada-identity-library';
import { ActionsIcon, AnnouncementIcon, AudioTabIcon, CalendarIcon, ClusterIcon, CommentsIcon, ContentIcon, CssIcon, CustomFieldsIcon, DirectoryIcon, DiscountIcon, FilterIcon, FlaggingIcon, FormIcon, FormsIcon, GlossaryIcon, GroupAccessIcon, GroupRequestsIcon, HelpDocsIcon, HollowImageIcon, HollowVideoIcon, InsightsIcon, InvitationsIcon, InviteIcon, LearningIcon, LibrariesIcon, MarketplaceIcon, MembersIcon, ModerationIcon, NotesTabIcon, NotificationIcon, NotifyUsersIcon, OrdersIcon, PartnersIcon, QuestionnaireIcon, QuestionsIcon, ReportingIcon, ResourceIcon, ResourcesIcon, RolesIcon, SimulationIcon, SliderIcon, SocialIcon, SubscriptionsIcon, TagsIcon, TopicsIcon, TranscriptTabIcon, UserFlowIcon, UserIcon, UsersIcon } from '@netfront/ui-library';
import { hasReportOptions } from 'components';
import { useRouter } from 'next/router';

import { CachingEntitiesContext } from 'context/CachingEntitiesContext';
import { ProjectSettingsContext } from 'context/ProjectSettingsContext';
import Announcement from 'public/images/announcement-icon.svg';
import CardApps from 'public/images/card-apps.svg';
import CardAudio from 'public/images/card-audio.svg';
import ContentImage from 'public/images/card-content.svg';
import CardDirectory from 'public/images/card-directory.svg';
import DiscountsImage from 'public/images/card-discounts.svg';
import CardDocument from 'public/images/card-document.svg';
import CardForm from 'public/images/card-form.svg';
import CardImage from 'public/images/card-image.svg';
import InsightsImage from 'public/images/card-insights.svg';
import CardInteractive from 'public/images/card-interactive.svg';
import LibraryImage from 'public/images/card-library.svg';
import ModerationImage from 'public/images/card-moderation.svg';
import NotificationImage from 'public/images/card-notifications.svg';
import ProgressTrackingImage from 'public/images/card-progress-tracking.svg';
import ReportingImage from 'public/images/card-reporting.svg';
import CardSlider from 'public/images/card-slider.svg';
import SocialImage from 'public/images/card-social.svg';
import SubscriptionsImage from 'public/images/card-subscriptions.svg';
import CardTag from 'public/images/card-tags.svg';
import TopicsImage from 'public/images/card-topic.svg';
import UserImage from 'public/images/card-users.svg';
import CardVideo from 'public/images/card-video.svg';
import CommunitiesImage from 'public/images/communities-icon.svg';
import ActivityImage from 'public/images/contentGroupTypes/activity.svg';
import BlogImage from 'public/images/contentGroupTypes/blog.svg';
import CartoonImage from 'public/images/contentGroupTypes/cartoon.svg';
import ConsentImage from 'public/images/contentGroupTypes/consent.svg';
import EventsImage from 'public/images/contentGroupTypes/events.svg';
import HelpImage from 'public/images/contentGroupTypes/help.svg';
import LearningImage from 'public/images/contentGroupTypes/learning.svg';
import NewsImage from 'public/images/contentGroupTypes/news.svg';
import PageImage from 'public/images/contentGroupTypes/page.svg';
import QuestionnaireImage from 'public/images/contentGroupTypes/questionnaire.svg';
import ResourcesImage from 'public/images/contentGroupTypes/resources.svg';
import Scheduler from 'public/images/icon-notification-scheduler.svg';
import NotificationTemplate from 'public/images/icon-notification-template.svg';
import LanguageFilterImage from 'public/images/language-filter-icon.svg';

import DashboardContext from './DashboardContext';
import { NetfrontServices } from './DashboardContext.constants';
import { filterVisibleItems, findObjectById } from './DashboardContext.helpers';
import { DashboardContextProps, IDashboardItemsList } from './DashboardContext.interfaces';



export function DashboardProvider({ children }: DashboardContextProps) {
  const { project } = useContext(CachingEntitiesContext);
  const { projectSettings } = useContext(ProjectSettingsContext);
  const { isDomainReady } = useDomain();
  const [dashboardItemsList, setDashboardItemsList] = useState<IDashboardItemsList[]>([]);
  const [isBetaVersion, setIsBetaVersion] = useState<boolean>(false);
  const [dashboardLink, setDashboardLink] = useState<string>('');
  const { query: { isBetaVersion: queryIsBetaVersion } } = useRouter();

  const { getDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });

  const { getBaseUrl: getIdentitySiteBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  useEffect(() => {
    if (!(project && isDomainReady && projectSettings)) {
      return;
    }

    const { services, id: projectId, organisation, isCustomBuild = false } = project;

    const ekardoBaseUrl = `/dashboard/${String(organisation.key)}/${String(projectId)}`;
    const backofficeBaseUrl = `${getDashboardUrl()}/${String(organisation.key)}/${String(projectId)}`;
    const identityBaseUrl = getIdentitySiteBaseUrl();

    const cardItems: IDashboardItemsList[] = [
      {
        id: 'content',
        description: 'Project content page',
        isVisible: (
          services?.includes('EKARDO')
          && (
            projectSettings['HAS_CONTENT_TYPE_LEARNING'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_RESOURCE'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_PAGE'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_NEWS'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_BLOG'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_QUESTIONNAIRE'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_EVENTS'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_HELP'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_CONSENT'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_CARTOON'] as boolean
            || projectSettings['HAS_CONTENT_TYPE_ACTIVITY'] as boolean
          )
        ) ?? false,
        href: `${ekardoBaseUrl}/content${isBetaVersion ? '?isBetaVersion=true': ''}`,
        image: ContentImage.src,
        icon: ContentIcon,
        title: 'Content',
        subItems: [
          {
            id: 'learning',
            description: 'View and manage learning content',
            href: `${ekardoBaseUrl}/content/learning${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_LEARNING'] as boolean,
            image: LearningImage.src,
            icon: LearningIcon,
            title: 'Learning',
          },
          {
            id: 'resource',
            description: 'View and manage resources content',
            href: `${ekardoBaseUrl}/content/resource${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_RESOURCE'] as boolean,
            image: ResourcesImage.src,
            icon: ResourceIcon,
            title: 'Resources',
          },
          {
            id: 'page',
            description: 'View and manage pages content',
            href: `${ekardoBaseUrl}/content/page${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_PAGE'] as boolean,
            image: PageImage.src,
            icon: FormIcon,
            title: 'Page',
          },
          {
            id: 'news',
            description: 'View and manage news content',
            href: `${ekardoBaseUrl}/content/news${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_NEWS'] as boolean,
            image: NewsImage.src,
            icon: TranscriptTabIcon,
            title: 'News',
          },
          {
            id: 'blog',
            description: 'View and manage blog content',
            href: `${ekardoBaseUrl}/content/blog${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_BLOG'] as boolean,
            image: BlogImage.src,
            icon: ResourceIcon,
            title: 'Blog',
          },
          {
            id: 'questionnaire',
            description: 'View and manage questionnaire content',
            href: `${ekardoBaseUrl}/content/questionnaire${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_QUESTIONNAIRE'] as boolean,
            image: QuestionnaireImage.src,
            icon: QuestionnaireIcon,
            title: 'Questionnaire',
          },
          {
            id: 'events',
            description: 'View and manage events content',
            href: `${ekardoBaseUrl}/content/events${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_EVENTS'] as boolean,
            image: EventsImage.src,
            icon: CalendarIcon,
            title: 'Events',
          },
          {
            id: 'help',
            description: 'View and manage help content',
            href: `${ekardoBaseUrl}/content/help${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_HELP'] as boolean,
            image: HelpImage.src,
            icon: HelpDocsIcon,
            title: 'Help',
          },
          {
            id: 'consent',
            description: 'View and manage consent content',
            href: `${ekardoBaseUrl}/content/consent${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_CONSENT'] as boolean,
            image: ConsentImage.src,
            icon: NotesTabIcon,
            title: 'Consent',
          },
          {
            id: 'cartoon',
            description: 'View and manage cartoon content',
            href: `${ekardoBaseUrl}/content/cartoon${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_CARTOON'] as boolean,
            image: CartoonImage.src,
            icon: UserIcon,
            title: 'Cartoon',
          },
          {
            id: 'activity',
            description: 'View and manage activity content',
            href: `${ekardoBaseUrl}/content/activity${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_CONTENT_TYPE_ACTIVITY'] as boolean,
            image: ActivityImage.src,
            icon: ActionsIcon,
            title: 'Activity',
          },
        ],
      },
      {
        id: 'library',
        description: 'Project library page',
        href: `${ekardoBaseUrl}/library`,
        isVisible: (services?.includes('EKARDO') && (
          projectSettings['HAS_IMAGE_LIBRARY'] as boolean
          || projectSettings['HAS_DOCUMENT_LIBRARY'] as boolean
          || projectSettings['HAS_VIDEO_LIBRARY'] as boolean
          || projectSettings['HAS_AUDIO_LIBRARY'] as boolean
          || projectSettings['HAS_FORM_LIBRARY'] as boolean
          || projectSettings['HAS_SLIDER_LIBRARY'] as boolean
          || projectSettings['HAS_APP_LIBRARY'] as boolean
          || projectSettings['HAS_TAG_LIBRARY'] as boolean
          || projectSettings['HAS_DIRECTORY_LIBRARY'] as boolean
          || projectSettings['HAS_INTERACTIVE_REGIONS_LIBRARY'] as boolean
          || projectSettings['HAS_GLOSSARY_LIBRARY'] as boolean
          || projectSettings['HAS_CHARACTER_LIBRARY'] as boolean
          || projectSettings['HAS_CUSTOM_CSS_LIBRARY'] as boolean
        )) ?? false,
        image: LibraryImage.src,
        icon: LibrariesIcon,
        title: 'Libraries',
        subItems: [
          {
            id: 'image',
            description: 'Project library images page',
            href: `${ekardoBaseUrl}/library/assets/images`,
            isVisible: projectSettings['HAS_IMAGE_LIBRARY'] as boolean,
            image: CardImage.src,
            icon: HollowImageIcon,
            title: 'Image',
          },
          {
            id: 'document',
            description: 'Project library documents page',
            href: `${ekardoBaseUrl}/library/assets/documents`,
            isVisible: projectSettings['HAS_DOCUMENT_LIBRARY'] as boolean,
            image: CardDocument.src,
            icon: ResourcesIcon,
            title: 'Document',
          },
          {
            id: 'video',
            description: 'Project library videos page',
            href: `${ekardoBaseUrl}/library/assets/videos`,
            isVisible: projectSettings['HAS_VIDEO_LIBRARY'] as boolean,
            image: CardVideo.src,
            icon: HollowVideoIcon,
            title: 'Video',
          },
          {
            id: 'audio',
            description: 'Project library audios page',
            href: `${ekardoBaseUrl}/library/assets/audios`,
            isVisible: projectSettings['HAS_AUDIO_LIBRARY'] as boolean,
            image: CardAudio.src,
            icon: AudioTabIcon,
            title: 'Audio',
          },
          {
            id: 'form',
            description: 'Project library forms page',
            href: `${ekardoBaseUrl}/library/forms${isBetaVersion ? '?isBetaVersion=true': ''}`,
            isVisible: projectSettings['HAS_FORM_LIBRARY'] as boolean,
            image: CardForm.src,
            icon: FormsIcon,
            title: 'Forms',
          },
          {
            id: 'slider',
            description: 'Project library slider page',
            href: `${ekardoBaseUrl}/library/slider`,
            isVisible: projectSettings['HAS_SLIDER_LIBRARY'] as boolean,
            image: CardSlider.src,
            icon: SliderIcon,
            title: 'Slider',
          },
          {
            id: 'app',
            description: 'Project library apps page',
            href: `${ekardoBaseUrl}/library/apps`,
            isVisible: !isCustomBuild && projectSettings['HAS_APP_LIBRARY'] as boolean,
            image: CardApps.src,
            icon: MarketplaceIcon,
            title: 'App',
            subItems: [
              {
                id: 'project-apps',
                description: 'Project apps page',
                href: `${ekardoBaseUrl}/library/apps/project-apps`,
                icon: MarketplaceIcon,
                image: CardApps.src,
                title: 'Project apps',
                isVisible: true,
              },
              {
                id: 'marketplace',
                description: 'Marketplace apps page',
                href: `${ekardoBaseUrl}/library/apps/marketplace`,
                icon: MarketplaceIcon,
                image: CardApps.src,
                title: 'Marketplace',
                isVisible: true,
              },
              {
                id: 'pending-app-approvals',
                description: 'Marketplace app approvals page',
                href: `${ekardoBaseUrl}/library/apps/pending-app-approvals`,
                icon: MarketplaceIcon,
                image: CardApps.src,
                title: 'Pending approvals',
                isVisible: true,
              },
            ],
          },
          {
            id: 'tags',
            description: 'Project library tags page',
            href: `${ekardoBaseUrl}/library/tags`,
            isVisible: projectSettings['HAS_TAG_LIBRARY'] as boolean,
            image: CardTag.src,
            icon: TagsIcon,
            title: 'Tags',
          },
          {
            id: 'directory',
            description: 'Project library directory page',
            href: `${ekardoBaseUrl}/library/directory`,
            isVisible: projectSettings['HAS_DIRECTORY_LIBRARY'] as boolean,
            image: CardDirectory.src,
            icon: DirectoryIcon,
            title: 'Directory',
          },
          {
            id: 'interactive-regions',
            description: 'Project library Interactive regions page',
            href: `${backofficeBaseUrl}/library/imageMap`,
            isInternal: false,
            isVisible: projectSettings['HAS_INTERACTIVE_REGIONS_LIBRARY'] as boolean,
            image: CardInteractive.src,
            icon: ClusterIcon,
            title: 'Interactive regions',
          },
          {
            id: 'glossary',
            description: 'Project library glossary page',
            href: `${ekardoBaseUrl}/library/glossary`,
            isVisible: projectSettings['HAS_GLOSSARY_LIBRARY'] as boolean,
            image: CardForm.src,
            icon: GlossaryIcon,
            title: 'Glossary',
          },
          {
            id: 'character',
            description: 'Project library character page',
            href: `${ekardoBaseUrl}/library/character`,
            isVisible: projectSettings['HAS_CHARACTER_LIBRARY'] as boolean,
            image: CartoonImage.src,
            icon: UserIcon,
            title: 'Character',
          },
          {
            id: 'custom-css',
            description: 'Project custom css page',
            href: `${ekardoBaseUrl}/library/custom-css`,
            isVisible: projectSettings['HAS_CUSTOM_CSS_LIBRARY'] as boolean,
            image: CardImage.src,
            icon: CssIcon,
            title: 'Custom css',
          },
        ],
      },
      {
        id: 'users',
        description: 'Project users page',
        href: `${identityBaseUrl}/project/${String(projectId)}/user`,
        isInternal: false,
        image: UserImage.src,
        icon: PartnersIcon,
        title: 'Users',
        subItems: [
          {
            icon: InvitationsIcon,
            title: 'Invitations',
            id: 'invitations',
            isInternal: false,
            href: `${identityBaseUrl}/project/${String(projectId)}/user/invitations`,
            description: 'Project user invitations',
            image: '',
          },
          {
            icon: GroupRequestsIcon,
            title: 'Group requests',
            description: 'Project group requests',
            id: 'group-requests',
            isInternal: false,
            image: '',
            isVisible: projectSettings['HAS_GROUP_REQUEST'] as boolean,
            href: `${identityBaseUrl}/project/${String(projectId)}/user/group-requests`,
          },
          {
            icon: UsersIcon,
            title: 'Groups',
            description: 'Project groups',
            id: 'groups',
            isInternal: false,
            image: '',
            href: `${identityBaseUrl}/project/${String(projectId)}/user/groups`,
          },
          {
            icon: RolesIcon,
            title: 'Roles',
            description: 'Project roles',
            id: 'roles',
            isInternal: false,
            image: '',
            href: `${identityBaseUrl}/project/${String(projectId)}/user/roles`,
          },
          {
            icon: CustomFieldsIcon,
            title: 'Custom fields',
            id: 'custom-fields',
            description: 'Project custom fields',
            isInternal: false,
            image: '',
            href: `${identityBaseUrl}/project/${String(projectId)}/user/custom-fields`,
          },
        ],
      },
      {
        id: 'subscriptions',
        isVisible: (services?.includes(NetfrontServices.MURIQUI as DBNetfrontServiceType) && services.includes(NetfrontServices.VERVET as DBNetfrontServiceType)) ?? false,
        description: 'Project subscriptions page',
        href: `${ekardoBaseUrl}/subscriptions`,
        image: SubscriptionsImage.src,
        icon: SubscriptionsIcon,
        title: 'Subscriptions',
        subItems: [
          {
            id: 'orders',
            description: 'Project subscriptions orders page',
            href: `${ekardoBaseUrl}/subscriptions/orders`,
            image: SubscriptionsImage.src,
            icon: OrdersIcon,
            title: 'Orders',
          },
          {
            id: 'discount-codes',
            description: 'Project subscriptions discount codes page',
            href: `${ekardoBaseUrl}/subscriptions/discounts`,
            image: DiscountsImage.src,
            icon: DiscountIcon,
            title: 'Discount codes',
          },
        ],
      },
      {
        id: 'social',
        description: 'Project social page',
        href: `${ekardoBaseUrl}/social`,
        image: SocialImage.src,
        icon: SocialIcon,
        isVisible: services?.includes(`${NetfrontServices.BONOBO as DBNetfrontServiceType}`) ?? false,
        title: 'Social',
        subItems: [
          {
            id: 'moderation',
            description: 'Project social moderation page',
            href: `${ekardoBaseUrl}/social/moderation`,
            icon: ModerationIcon,
            image: ModerationImage.src,
            isVisible: projectSettings['HAS_MODERATION_MANAGEMENT'] as boolean,
            title: 'Moderation',
          },
          {
            id: 'topics',
            description: 'Project social topics page',
            href: `${ekardoBaseUrl}/social/topics`,
            icon: TopicsIcon,
            image: TopicsImage.src,
            title: 'Topics',
            isVisible: projectSettings['HAS_TOPIC'] as boolean,
          },
          {
            id: 'communities',
            description: 'Project social communities page',
            href: `${ekardoBaseUrl}/social/communities`,
            icon: GroupRequestsIcon,
            image: CommunitiesImage.src,
            title: 'Communities',
            isVisible: projectSettings['HAS_COMMUNITY'] as boolean,
          },
          {
            id: 'events',
            description: 'Project social events page',
            href: `${ekardoBaseUrl}/social/events`,
            icon: CalendarIcon,
            image: Scheduler.src,
            title: 'Events',
            isVisible: projectSettings['HAS_EVENT_COMMUNITIES'] as boolean,
          },
          {
            id: 'users',
            description: 'User social communities page',
            href: `${ekardoBaseUrl}/social/users`,
            icon: MembersIcon,
            image: UserImage.src,
            title: 'Users',
            isVisible: projectSettings['HAS_USER_MANAGEMENT'] as boolean,
          },
          {
            id: 'language-filter',
            description: 'User social language filter page',
            href: `${ekardoBaseUrl}/social/language-filter`,
            icon: FlaggingIcon,
            image: LanguageFilterImage.src,
            title: 'Language filters',
            isVisible: projectSettings['HAS_LANGUAGE_FILTER'] as boolean,
          },
        ]
      },
      {
        id: 'insights',
        description: 'Project insights page',
        href: `${ekardoBaseUrl}/insight`,
        image: InsightsImage.src,
        icon: InsightsIcon,
        isVisible: projectSettings['HAS_INSIGHTS'] as boolean,
        title: 'Insight',
        subItems: [],
      },
      {
        id: 'progress-tracking',
        description: 'Project progress tracking page',
        href: `${ekardoBaseUrl}/progress-tracking`,
        image: ProgressTrackingImage.src,
        icon: UserFlowIcon,
        isVisible: projectSettings['HAS_PROGRESS_TRACKER'] as boolean,
        title: 'Progress tracking',
        subItems: [],
      },
      {
        id: 'reporting',
        description: 'Project reporting page',
        href: `${ekardoBaseUrl}/reporting`,
        image: ReportingImage.src,
        icon: ReportingIcon,
        title: 'Reporting',
        subItems: [
          {
            id: 'reporting-content',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-content`,
            icon: ContentIcon,
            image: '',
            isVisible: (
              services?.includes(NetfrontServices.EKARDO as DBNetfrontServiceType)
              &&
              hasReportOptions({ 
                projectId, 
                projectSettings, 
                services, 
                route: 'reporting-content'
              })
            ) ?? false,
            title: 'Content',
          },
          {
            id: 'reporting-feedback',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-feedback`,
            icon: CommentsIcon,
            image: '',
            isVisible: (
              projectSettings['HAS_FEEDBACK_REPORT'] as boolean
              &&
              hasReportOptions({ 
                projectId, 
                projectSettings,
                services, 
                route: 'reporting-feedback'
              })
            ),
            title: 'Feedback',
          },
          {
            id: 'reporting-notifications',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-notifications`,
            icon: NotifyUsersIcon,
            image: '',
            isVisible: (
              projectSettings['HAS_NOTIFICATION_REPORT'] as boolean
              &&
              hasReportOptions({ 
                projectId, 
                projectSettings,
                services, 
                route: 'reporting-notifications'
              })
            ),
            title: 'Notifications',
          },
          {
            id: 'reporting-questionnaires',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-questionnaires`,
            icon: QuestionsIcon,
            image: '',
            isVisible: (
              projectSettings['HAS_QUESTIONNAIRE_REPORT'] as boolean
              &&
              hasReportOptions({ 
                projectId, 
                projectSettings,
                services, 
                route: 'reporting-questionnaires'
              })
            ),
            title: 'Questionnaires',
          },
          {
            id: 'reporting-social',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-social`,
            icon: SocialIcon,
            image: '',
            isVisible: (
              projectSettings['HAS_SOCIAL_REPORTS'] as boolean
              &&
              hasReportOptions({ 
                projectId, 
                projectSettings,
                services, 
                route: 'reporting-social'
              })
            ),
            title: 'Social',
          },
          {
            id: 'reporting-user-entry',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-user-entry`,
            icon: FormIcon,
            image: '',
            isVisible: (
              services?.includes(NetfrontServices.TRIPLE_E as DBNetfrontServiceType)
              &&
              hasReportOptions({ 
                projectId, 
                projectSettings,
                services, 
                route: 'reporting-user-entry'
              })
            ) ?? false,
            title: 'User entry',
          },
          {
            id: 'reporting-users',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-users`,
            icon: PartnersIcon,
            image: '',
            isVisible: hasReportOptions({ 
              projectId, 
              projectSettings,
              services, 
              route: 'reporting-users'
            }),
            title: 'Users',
          },
          {
            id: 'reporting-custom-report',
            description: '',
            href: `${ekardoBaseUrl}/reporting/reporting-custom-report`,
            icon: CustomFieldsIcon,
            image: '',
            isVisible: (
                projectSettings['HAS_CUSTOM_REPORT'] as boolean 
                && 
                hasReportOptions({ 
                  projectId, 
                  projectSettings,
                  services, 
                  route: 'reporting-custom-report'
                })
            ),
            title: 'Custom report',
          },
          
        ],
      },
      {
        id: 'notifications',
        isVisible: services?.includes(`${NetfrontServices.HOWLER as DBNetfrontServiceType}`) ?? services?.includes(`${NetfrontServices.CAPUCHIN  as DBNetfrontServiceType}`) ?? false,
        description: 'Project notifications page',
        href: `${ekardoBaseUrl}/notifications`,
        image: NotificationImage.src,
        icon: NotificationIcon,
        title: 'Notifications',
        subItems: [
          {
            href: `${ekardoBaseUrl}/notifications/scheduler`,
            icon: CalendarIcon,
            image: Scheduler.src,
            description: 'Scheduler dashboard',
            id: 'scheduler',
            isVisible: projectSettings['HAS_SCHEDULER'] as boolean,
            title: 'Scheduler',
            subItems: [
              {
                id: 'scenarios',
                description: 'Project scenarios page',
                href: `${ekardoBaseUrl}/notifications/scheduler/scenarios`,
                icon: FilterIcon,
                image: '',
                isVisible: services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false,
                title: 'Scenarios',
                subItems: [
                  {
                    id: 'scenario-users',
                    description: 'Manage user scenario information',
                    href: `${ekardoBaseUrl}/notifications/scheduler/scenarios/users`,
                    icon: UserIcon,
                    image: '',
                    isVisible: services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false,
                    title: 'Users',
                    
                  },
                  {
                    id: 'scenario-invitations',
                    description: 'Manage invitation scenario information',
                    href: `${ekardoBaseUrl}/notifications/scheduler/scenarios/invitations`,
                    icon: InviteIcon,
                    image: '',
                    isVisible: services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false,
                    title: 'Invitations',
                    
                  },
                ]
              },
              {
                id: 'simulator',
                description: 'Simulate scenarios',
                href: `${ekardoBaseUrl}/notifications/scheduler/simulator`,
                icon: SimulationIcon,
                image: '',
                isVisible: services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false,
                title: 'Simulator',
                
              },
              {
                id: 'test-mode',
                description: 'Test mode',
                href: `${ekardoBaseUrl}/notifications/scheduler/test-mode`,
                icon: UserFlowIcon,
                image: '',
                isVisible: services?.includes(NetfrontServices.CAPUCHIN as DBNetfrontServiceType) ?? false,
                title: 'Test mode',
                
              },
            ]
          },
          {
            id: 'notify',
            description: 'Notify',
            href: `${ekardoBaseUrl}/notifications/notify`,
            icon: NotifyUsersIcon,
            image: LibraryImage.src,
            isVisible: projectSettings['HAS_NOTIFY_USERS'] as boolean,
            title: 'Notify users',
          },
          {
            id: 'templates',
            image: NotificationTemplate.src,
            icon: TranscriptTabIcon,
            description: 'Templates',
            href: `${backofficeBaseUrl}/notifications/notification-templates`,
            isInternal: false,
            isVisible: services?.includes(NetfrontServices.HOWLER as DBNetfrontServiceType) ?? false,
            title: 'Templates',
          },
          {
            id: 'announcements',
            description: 'Announcements',
            icon: AnnouncementIcon,
            href: `${ekardoBaseUrl}/notifications/announcements`,
            image: Announcement.src,
            isVisible: projectSettings['HAS_ANNOUNCEMENTS'] as boolean,
            title: 'Announcements',
          },
        ]
      },
      {
        id: 'group-access',
        isVisible: services?.includes(`${NetfrontServices.VERVET as DBNetfrontServiceType}`) ?? false,
        description: 'Project group access page',
        href: `${ekardoBaseUrl}/group-access`,
        image: SubscriptionsImage.src,
        icon: GroupAccessIcon,
        title: 'Group access',
        subItems: [],
      },
    ];

    setDashboardItemsList(filterVisibleItems(cardItems));
    setDashboardLink(ekardoBaseUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, isDomainReady, projectSettings, isBetaVersion]);

  useEffect(() => {
    setIsBetaVersion( queryIsBetaVersion === 'true' && projectSettings?.['HAS_NEW_BUILDER'] as boolean);
  }, [queryIsBetaVersion, projectSettings]);



  const getDashboardItemsById = (id: string): IDashboardItemsList[] => {
    if (id === 'project') return dashboardItemsList;
    const dashboardItem = findObjectById(dashboardItemsList, id);
    return dashboardItem ?? [];
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboardItems: dashboardItemsList,
        getDashboardItemsById,
        dashboardLink,
        isLoading: dashboardItemsList.length === 0
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
