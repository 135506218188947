import { gql } from '@apollo/client';

const UPDATE_SLIDER_ITEM = gql`
  mutation UpdateSliderItem($sliderItem: UpdateSliderItemInput!) {
    slider {
      updateSliderItem(sliderItem: $sliderItem) {
        id
      }
    }
  }
`;

export { UPDATE_SLIDER_ITEM };
