import { gql } from '@apollo/client';

const DELETE_SLIDER = gql`
  mutation DeleteSlider($sliderId: Int!) {
    slider {
      deleteSlider(sliderId: $sliderId)
    }
  }
`;

export { DELETE_SLIDER };
