
import { ISlidersTableData, ISlidersTableDataParams } from "./LibrarySliderPage.interfaces";


const getFormsTableData = ({ sliderItems, onSettingsButtonClick }: ISlidersTableDataParams): ISlidersTableData[] => {
  return sliderItems.map(({  id, title }) => {
    return {
      title,
      id,
      settingsButtonData: {
        id,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getFormsTableData };
