import 'backpack.css';

import '@netfront/ekardo-content-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';
import '@netfront/ui-library/dist/css/typography.css';

import '../styles/components.css';
import '../styles/fonts.css';
import '../styles/globals.css';
import '../styles/theme.css';

import { useEffect, useState, createContext, useContext } from 'react';

import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import { NextPageContext, NextComponentType } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { CookiesProvider } from 'react-cookie';
import { Toaster } from 'react-hot-toast';

import { AppDetailsProvider, CachingEntitiesProvider, DashboardProvider, UserProvider, ProjectSettingsProvider } from '../context';

// Create a context to pass route information
const RouteContext = createContext({ isIframeRoute: false });

export const useRouteContext = () => useContext(RouteContext);

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [isIframeRoute, setIsIframeRoute] = useState(false);
  useEffect(() => {
    // Check if the current route is part of the iframe content
    const isIframe = router.pathname.startsWith('/content-preview');
    setIsIframeRoute(isIframe);

    // Dynamically import CSS based on the route
    {!isIframe && (
      <style global jsx>{`
        @import '../styles/components.css';
        @import '../styles/fonts.css';
        @import '../styles/globals.css';
        @import '../styles/theme.css';
      `}</style>
    )}

  }, [router.pathname]);

  const googleAnalyticsMeasurementId = String(process.env.REACT_APP_GOOGLE_ANALYTICS);
  const isGoogleAnalyticsDebugMode = String(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE).toUpperCase() === 'Y';

  const { trackPageView, trackUser } = useGoogleAnalytics();
  const { events } = useRouter();
  const { getUser } = useUser();

  const [gtagConfigParams, setGtagConfigParams] = useState<ReturnType<typeof omitBy>>();

  const user = getUser();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView(url);
      trackUser();
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, user]);
  useEffect(() => {
    if (gtagConfigParams || !user) {
      return;
    }

    setGtagConfigParams(() =>
      omitBy(
        {
          debug_mode: isGoogleAnalyticsDebugMode ? true : undefined,
          user_id: String(user.id),
        },
        isNil,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtagConfigParams, user]);

  return (
    <RouteContext.Provider value={{ isIframeRoute }}>
      {isIframeRoute ? (
        <Component {...pageProps} />
      ) : (
        <div id="root">
          {gtagConfigParams && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
            gtag('config', '${googleAnalyticsMeasurementId}'${
                  Object.keys(gtagConfigParams).length ? `, ${JSON.stringify(gtagConfigParams)}` : ''
                });
          `,
              }}
            />
          )}
          <CachingEntitiesProvider>
            <ProjectSettingsProvider>
              <UserProvider>
                <DashboardProvider>
                  <CookiesProvider>
                    <AppDetailsProvider>
                      <Toaster
                        position="top-center"
                        reverseOrder={false}
                        toastOptions={{
                          duration: 3000,
                        }}
                      />
                      <Component {...pageProps} />
                    </AppDetailsProvider>
                  </CookiesProvider>
                </DashboardProvider>
              </UserProvider>
            </ProjectSettingsProvider>
          </CachingEntitiesProvider>
        </div>
      )}
    </RouteContext.Provider>
  );
}

App.getInitialProps = async ({ Component, ctx }: { Component: NextComponentType; ctx: NextPageContext }) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  // Determine if the route is for the iframe
  const isIframeRoute = ctx.pathname.startsWith('/content-preview');

  return { pageProps: { ...pageProps, isIframeRoute } };
};

// eslint-disable-next-line import/no-default-export
export default App;
