import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DELETE_SLIDER } from './useDeleteSlider.graphql';
import {
  IDeleteSliderMutationGraphQLResponse,
  IDeleteSliderMutationVariables,
  IHandleDeleteSliderParams,
  IUseDeleteSliderOptions,
  IUseDeleteSlider,
} from './useDeleteSlider.interfaces';

const useDeleteSlider = (options?: IUseDeleteSliderOptions): IUseDeleteSlider => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseDeleteSliderOptions);

  const [executeDeleteSlider, { loading: isLoading }] = useEkardoMutation<
  IDeleteSliderMutationGraphQLResponse,
  IDeleteSliderMutationVariables
  >({
    mutation: mutation ?? DELETE_SLIDER,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          deleteSlider: isCompleted,
        } = data;

        void onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteSlider = ({ sliderId }: IHandleDeleteSliderParams) => {
    void executeDeleteSlider({
      variables: {
        sliderId,
      },
    });
  };

  return {
    handleDeleteSlider,
    isLoading,
  };
};

export { useDeleteSlider };
