import { useState, useRef } from 'react';

import { ButtonIconOnly, } from '@netfront/ui-library';

import { devices } from './PagePreviewer.constants';
import { IDeviceOption } from './PagePreviewer.interfaces';
import styles from './PagePreviewer.module.css';



const PagePreviewerIframe = ({ activeDevice, pageId, projectId }: { activeDevice: IDeviceOption; pageId: number; projectId: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <div className={styles.container}>
      <iframe
        ref={iframeRef}
        className={styles.preview}
        frameBorder="0"
        height={activeDevice.height}
        src={`/content-preview/${projectId}/${pageId}`}
        width={activeDevice.width}
      ></iframe>
    </div>
  );
};

const PagePreviewer = ({ contentPageId, projectId }: { contentPageId: number; projectId: string }) => {
  const [activeDevice, setActiveDevice] = useState('desktop');

  return (
    <>
      <header className={styles.switcher}>
        <h1 className="h-hide-visually">Preview Page</h1>
        <p className="h-hide-visually">Choose a device to preview at that screen size</p>
        <div className="c-toolbar">
          {Object.keys(devices).map((key) => (
            <ButtonIconOnly
              key={key}
              icon={devices[key].icon}
              isIconSelected={activeDevice === key}
              text={devices[key].name}
              onClick={() => setActiveDevice(key)}
            />
          ))}
        </div>
      </header>
      <PagePreviewerIframe activeDevice={devices[activeDevice]} pageId={contentPageId} projectId={projectId} />
    </>
  );
};

export { PagePreviewer };
