import { gql } from '@apollo/client';

const UPDATE_SLIDER_MUTATION = gql`
  mutation UpdateSlider($slider: UpdateSliderInput!) {
    slider {
      updateSlider(slider: $slider) {
        id
      }
    }
  }
`;

export { UPDATE_SLIDER_MUTATION };
