import { gql } from '@apollo/client';

const DELETE_SLIDER_ITEM = gql`
  mutation DeleteSliderItem($sliderItemId: Int!) {
    slider {
      deleteSliderItem(sliderItemId: $sliderItemId)
    }
  }
`;

export { DELETE_SLIDER_ITEM };
