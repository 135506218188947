import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledForm, Dialog, FormFieldProps, GeneralTabIcon, SideBarTabSet, SliderIcon, Spinner, TabSetImperativeHandleRef, useControlledForm } from '@netfront/ui-library';
import { SliderGeneralTab, SliderItemsTab, SliderPreview } from 'components';
import { useDeleteSlider, useGetSliderDetails, useToast, useUpsertSlider,  } from 'hooks';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { getSliderDefaultValues, getSliderVariables } from './LibrarySliderSidebarView.helpers';
import { LibrarySliderSidebarViewProps } from './LibrarySliderSidebarView.interfaces';


const LibrarySliderSidebarView = ({
  handleSideBarClose,
  isSideBarOpen = false,
  onUpdate,
  projectId,
  selectedSliderId,
}: LibrarySliderSidebarViewProps) => {

  const { handleToastError, handleToastSuccess } = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);


  const { control, handleSubmit, reset, setValue, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required().label('Title'),
        sliderItems: yup.array().required().min(1, 'Please provide at least one slide'),
      }),
    ),
  });

  const { handleGetSliderDetails, isLoading: isGetSliderDetailsLoading = false } = useGetSliderDetails({
    onCompleted: ({ slider }) => {
      setDefaultValues(getSliderDefaultValues(slider));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });



  const { handleDeleteSlider, isLoading: isDeleteSliderLoading = false } = useDeleteSlider({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      reset();

      handleToastSuccess({ message: 'Form successfully deleted'});

      onUpdate();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpsertSlider, isLoading: isUpsertSliderLoading = false } = useUpsertSlider({
    onCompleted: onUpdate,
  });

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  const handleSaveForm = (item: FormFieldProps) => {
    handleUpsertSlider({
      sliderId: selectedSliderId,
      projectId,
      ...getSliderVariables(item),
    });
  };

  useEffect(() => {
    if (selectedSliderId) {
      void handleGetSliderDetails({
        sliderId: selectedSliderId,
      })
    } else {
      setDefaultValues(getSliderDefaultValues());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSliderId, isSideBarOpen]);

  const isLoading = isGetSliderDetailsLoading || isDeleteSliderLoading || isUpsertSliderLoading;

  return (
    <>
      <Spinner isLoading={isLoading} spinnerIconSize={'small'} />
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete slider?"
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedSliderId) {
            return;
          }

          handleDeleteSlider({
            sliderId: selectedSliderId,
          });
        }}
      />
      <Dialog
        isOpen={isPreviewDialogOpen}
        title="Preview slider"
        onCancel={() => setIsPreviewDialogOpen(false)}
        onClose={() => setIsPreviewDialogOpen(false)}
      >
        <SliderPreview getValues={getValues} isOpen={isPreviewDialogOpen}/>
      </Dialog>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSaveForm(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >

        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={handleSideBarClose}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <SliderGeneralTab
                  control={control as Control<FormFieldProps>}
                  isLoading={isLoading}
                  onPreviewClick={() => setIsPreviewDialogOpen(true)}
                />
              )
            },
            {
              icon: SliderIcon,
              id: 'id_slider_items_tab',
              label: 'Items',
              view: () => (
                <SliderItemsTab
                  control={control as Control<FormFieldProps>}
                  getValues={getValues}
                  projectId={projectId}
                  setValue={setValue}
                  onPreviewClick={() => setIsPreviewDialogOpen(true)}
                />
              )
            },
          ]}
          onDelete={selectedSliderId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"

        />
      </ControlledForm>
    </>
  );
};

export { LibrarySliderSidebarView };
