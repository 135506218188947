/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, BinIcon, CaretDownIcon } from '@netfront/ui-library';
import { IEditableSliderItem } from 'interfaces';
import { stripHtmlMarkup } from 'utils';

import { SliderItemsOverviewProps } from './SliderItemsOverview.interfaces';


const SliderItemsOverview = ({ onEditClick, onDeleteClick, sliderItems = [] }: SliderItemsOverviewProps) => {
  const [items, setItems] = useState<IEditableSliderItem[]>([]);

  useEffect(() => {
    setItems(sliderItems);
  }, [sliderItems]);
  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'type',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Items</div>,
                width: '20%',
              },
              {
                accessor: 'title',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Items</div>,
                width: '75%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleEdit,
                    handleDelete,
                    tempId,
                    isHidden = false,
                  } }: {
                  value : {
                    handleDelete: (tempId: string) => void;
                    handleEdit: (tempId: string) => void;
                    isHidden?: boolean;
                    tempId: string
                  }
                }) => (
                  <>
                  {!isHidden && (
                    <FlexContainer gap="2x-small" justifyContent="end">
                      <ButtonIconOnly
                        additionalClassNames=""
                        icon={BinIcon}
                        isIconBorderVisible={false}
                        text="Delete slider item"
                        onClick={() => handleDelete(tempId)}
                        />
                      <ButtonIconOnly
                        additionalClassNames="h-rotate-right"
                        icon={CaretDownIcon}
                        isIconBorderVisible={false}
                        text="Edit slider item"
                        onClick={() => handleEdit(tempId)}
                        />
                    </FlexContainer>
                  )}
                  </>
                ),
              },
            ]}
            data={items.map((item) => (
              {
                id: item.tempId,
                type: item.type,
                title: stripHtmlMarkup(item.text) || item.quote || 'image',
                settingsButtonData: {
                  tempId: item.tempId,
                  handleEdit: onEditClick,
                  handleDelete: onDeleteClick,
                },
              }
            ))}
          />
        </Spacing>
      )}
    </>
  );
};

export { SliderItemsOverview };
