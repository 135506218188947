import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext } from 'context';
import { useGetLoginOverviewReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { LOGIN_OVERVIEW_COLUMNS } from './LoginOverviewPage.constants';
import { getLoginOverviewTableData } from './LoginOverviewPage.helpers';
import { LoginOverviewCsvRowData, ILoginOverviewItem, ILoginOverviewTableData } from './LoginOverviewPage.interfaces';



const LoginOverviewPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [loginOverviewItems, setLoginOverviewItems] = useState<ILoginOverviewItem[]>();
  const [allLoginOverviewItems, setAllLoginOverviewItems] = useState<ILoginOverviewItem[]>([]);
  const [loginOverviewTableData, setLoginOverviewTableData] = useState<ILoginOverviewTableData[]>([]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);


  const {
    handleGetLoginOverviewReport,
    isLoading: isGetLoginOverviewLoading = false,
  } = useGetLoginOverviewReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;
      setCsvUrl(presignedUrl);
      axios
        .get(presignedUrl)
        .then((res: AxiosResponse) => {

          const { data } = Papa.parse<LoginOverviewCsvRowData>(String(res.data), {
            header: true,
            skipEmptyLines: true,
          });

          const formattedItems = data.map((row: LoginOverviewCsvRowData) => ({
            id: Number(row['User id']),
            firstName: String(row['First name']),
            lastName: String(row['Last name']),
            email: String(row['Email']),
            groupName: String(row['Group name']),
            firstLogin: String(row['First login']),
            lastLogin: String(row['Last login']),
            loginCount: Number(row['Login count']),
          }));

          
          setTotalCount(data.length);
          setAllLoginOverviewItems(formattedItems);
          const paginatedItems = formattedItems.slice(0, pageSize);
          setLoginOverviewItems(paginatedItems);
          setLastItemIndex(pageSize);
          
        })
        .catch((error) => {
          handleToastCustomError({
            message: 'whoops! something went wrong',
          });
          // eslint-disable-next-line no-console
          console.log({ error });
        });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!projectId) {
      return;
    }

    void handleGetLoginOverviewReport({
      fileType: 'CSV',
      projectId,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setLoginOverviewItems(allLoginOverviewItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setLoginOverviewItems(allLoginOverviewItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!loginOverviewItems) {
      return;
    }

    setLoginOverviewTableData(
      getLoginOverviewTableData({
        items: loginOverviewItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginOverviewItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetLoginOverviewLoading;

  return (
    <ReportTable<ILoginOverviewTableData>
      activePath={['reporting', 'reporting-users', 'export-login-overview']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Logins',
      }]}
      columns={LOGIN_OVERVIEW_COLUMNS}
      csvUrl={csvUrl}
      data={loginOverviewTableData}
      description={`Login overview reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports login overview details based on the selected filters'}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onGenerate={generateReport}
    />
  );
};

export { LoginOverviewPage };
