

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { EXPORT_LOGIN_OVERVIEW } from './useGetLoginOverviewReport.graphql';
import {
  IGetLoginOverviewReportQueryGraphQLResponse,
  IGetLoginOverviewReportQueryVariables,
  IHandleGetLoginOverviewReportParams,
  IUseGetLoginOverviewReport,
  IUseGetLoginOverviewReportOptions,
} from './useGetLoginOverviewReport.interfaces';

const useGetLoginOverviewReport = (options?: IUseGetLoginOverviewReportOptions): IUseGetLoginOverviewReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetLoginOverviewReportOptions);

  const [executeGetLoginOverviewReport, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetLoginOverviewReportQueryGraphQLResponse,
    IGetLoginOverviewReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportLoginSummary } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportLoginSummary,
        });
      },
      onError,
    },
    query: query ?? EXPORT_LOGIN_OVERVIEW,
    token,
  });


  const handleGetLoginOverviewReport = async ({
    fileType,
    projectId,
  }: IHandleGetLoginOverviewReportParams) => {
    await executeGetLoginOverviewReport({
      variables: {
        fileType,
        projectId,
      },
    });
  };

  return {
    handleGetLoginOverviewReport,
    isLoading,
  };
};

export { useGetLoginOverviewReport };
