import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SLIDER_DETAILS } from './useGetSliderDetails.graphql';
import {
  IGetSliderDetailsQueryGraphQLResponse,
  IGetSliderDetailsQueryVariables,
  IUseGetSliderDetails,
  IUseGetSliderDetailsOptions,
} from './useGetSliderDetails.interfaces';

const useGetSliderDetails = (options?: IUseGetSliderDetailsOptions): IUseGetSliderDetails => {
  const { fetchPolicy, onCompleted, onError, token } = options ?? ({} as IUseGetSliderDetailsOptions);

  const [executeGetSliderDetails, { loading: isLoading }] = useEkardoLazyQuery<
    IGetSliderDetailsQueryGraphQLResponse,
    IGetSliderDetailsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          slider: { getSlider: slider },
        } = data;

        onCompleted({
          slider,
        });
      },
      onError,
    },
    query: GET_SLIDER_DETAILS,
    token,
  });

  const handleGetSliderDetails = async ({ sliderId }: IGetSliderDetailsQueryVariables) => {
    await executeGetSliderDetails({
      variables: {
        sliderId
      },
    });
  };

  return {
    handleGetSliderDetails,
    isLoading,
  };
};

export { useGetSliderDetails };
