import React from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  Button,
  FlexContainer,
  Input,
  Select,
  Spacing,
  Textarea,
  ToggleSwitch,
} from '@netfront/ui-library';
import { SidebarContainer } from 'components';
import { Controller } from 'react-hook-form';

import { SliderGeneralTabProps } from './SliderGeneralTab.interfaces';


const SliderGeneralTab = ({
  control,
  isLoading = false,
  onPreviewClick,
}: SliderGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <SidebarContainer hasPaddingEnds>
      <Spacing>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_form_title"
              isLoading={isLoading}
              labelText="Title"
              tooltipText="Title of the slider"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_description"
              isLoading={isLoading}
              labelText="Description"
              placeholder="Add your description here"
              tooltipText="Description of the slider"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="animation"
          render={({ field, fieldState }) => (
            <Select
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_slider_animation"
              isLoading={isLoading}
              labelText="Animation"
              options={[
                {
                  id: 'LINEAR',
                  value: 'LINEAR',
                  name: 'Linear',
                },
                {
                  id: 'FADE',
                  value: 'FADE',
                  name: 'Fade',
                },
                
              ]}
              tooltipText="Type of the animation for the slider"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="hasArrows"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_has_arrows"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Display arrows"
              tooltipPosition="start"
              tooltipText="Determine whether to display arrows on the slider"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="hasDots"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_has_dots"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Display dots"
              tooltipPosition="start"
              tooltipText="Determine whether to display dots at the bottom of the slider to show what slider item is currently in view"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="isAutoplay"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_is_autoplay"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Autoplay"
              tooltipPosition="start"
              tooltipText="Determine whether the slider scrolls through slider items automatically"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="isInfinite"
          render={({ field }) => (
            <ToggleSwitch
              additionalClassNames="c-sidebar-toggle"
              id="id_is_infinite"
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText="Loop?"
              tooltipPosition="start"
              tooltipText="Determine whether the slider infinitely loops over each item"
              isInline
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="autoplaySpeed"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_autoplay_speed"
              isLoading={isLoading}
              labelText="Autoplay speed"
              tooltipText="How much of a delay before the slider moves to the next item"
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      
      <Spacing>
        <Controller
          control={control}
          name="speed"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_speed"
              isLoading={isLoading}
              labelText="Transition speed"
              tooltipText="How quickly the next item transitions into view"
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="slidesToShow"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_slides_to_show"
              isLoading={isLoading}
              labelText="Slides to show"
              tooltipText="How many slides to show at one time"
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>

      <FlexContainer justifyContent="flex-end">
        <Button size="xs" text="Preview" type="button" variant="primary" onClick={onPreviewClick} />
      </FlexContainer>
    </SidebarContainer>
  );
};

export { SliderGeneralTab };
