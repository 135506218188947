import { DesktopIcon, TabletIcon, MobileIcon } from "@netfront/ui-library";

import { IDeviceOption } from "./PagePreviewer.interfaces";

const devices: { [key: string]: IDeviceOption} = {
  desktop: {
    name: 'Desktop',
    icon: DesktopIcon,
    width: '100%',
    height: 768,
  },
  tablet: {
    name: 'Tablet',
    icon: TabletIcon,
    width: 768,
    height: 1024,
  },
  mobile: {
    name: 'Mobile',
    icon: MobileIcon,
    width: 320,
    height: 568,
  },
};

export { devices };