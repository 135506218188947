import { Column } from 'react-table';

import { ILoginOverviewTableData } from './LoginOverviewPage.interfaces';



const LOGIN_OVERVIEW_COLUMNS: ReadonlyArray<Column<ILoginOverviewTableData>> = [

  {
    accessor: 'id',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User id</div>,
    width: '7%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Email</div>,
    width: '30%',
  },
  {
    accessor: 'firstName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">First name</span>,
    width: '10%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Last name</span>,
    width: '10%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Group name</span>,
    width: '10%',

  },
  {
    accessor: 'firstLogin',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Login date</span>,
    width: '14%',
  },
  {
    accessor: 'lastLogin',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Login date</span>,
    width: '14%',
  },
  {
    accessor: 'loginCount',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <span className="text-left w-full">Login date</span>,
    width: '5%',
  },
];

export { LOGIN_OVERVIEW_COLUMNS };
