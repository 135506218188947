import { gql } from '@apollo/client';

const EXPORT_LOGIN_OVERVIEW = gql`
  query ExportLoginOverview($projectId: String!, $fileType: FileType) {
    report {
      exportLoginSummary(projectId: $projectId, fileType: $fileType) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_LOGIN_OVERVIEW };
