import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { UPDATE_SLIDER_MUTATION } from './useUpdateSlider.graphql';
import {
  IUpdateSliderMutationGraphQLResponse,
  IUpdateSliderMutationVariables,
  IHandleUpdateSliderParams,
  IUseUpdateSliderOptions,
  IUseUpdateSlider,
} from './useUpdateSlider.interfaces';

const useUpdateSlider = (options?: IUseUpdateSliderOptions): IUseUpdateSlider => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateSliderOptions);

  const [executeUpdateSlider, { loading: isLoading }] = useEkardoMutation<
  IUpdateSliderMutationGraphQLResponse,
  IUpdateSliderMutationVariables
  >({
    mutation: mutation ?? UPDATE_SLIDER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          slider: {
            updateSlider: slider,
          } 
        } = data;

        void onCompleted({ slider });
      },
      onError,
    },
    token,
  });

  const handleUpdateSlider = ({
    animation,
    arrows,
    autoplay,
    autoplaySpeed,
    description,
    dots,
    height,
    infinite,
    sliderId,
    slidesToScroll,
    slidesToShow,
    speed,
    title,
    type,
  }: IHandleUpdateSliderParams) => {
    void executeUpdateSlider({
      variables: {
        slider: {
          animation,
          arrows,
          autoplay,
          autoplaySpeed,
          description,
          dots,
          height,
          infinite,
          sliderId,
          slidesToScroll,
          slidesToShow,
          speed,
          title,
          type,
        },
      },
    });
  };

  return {
    handleUpdateSlider,
    isLoading,
  };
};

export { useUpdateSlider };
