import { gql } from '@apollo/client';

const CREATE_SLIDER_ITEM_MUTATION = gql`
  mutation CreateSliderItem($sliderItem: CreateSliderItemInput!) {
    slider {
      createSliderItem(sliderItem: $sliderItem) {
        id
      }
    }
  }
`;

export { CREATE_SLIDER_ITEM_MUTATION };
