import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_SLIDER_ITEM_MUTATION } from './useCreateSliderItem.graphql';
import {
  ICreateSliderItemMutationGraphQLResponse,
  ICreateSliderItemMutationVariables,
  IHandleCreateSliderItemParams,
  IUseCreateSliderItemOptions,
  IUseCreateSliderItem,
} from './useCreateSliderItem.interfaces';

const useCreateSliderItem = (options?: IUseCreateSliderItemOptions): IUseCreateSliderItem => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseCreateSliderItemOptions);

  const [executeCreateSliderItem, { loading: isLoading }] = useEkardoMutation<
  ICreateSliderItemMutationGraphQLResponse,
  ICreateSliderItemMutationVariables
  >({
    mutation: mutation ?? CREATE_SLIDER_ITEM_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          slider: {
            createSliderItem: sliderItem,
          } 
        } = data;

        void onCompleted({ sliderItem });
      },
      onError,
    },
    token,
  });

  const handleCreateSliderItem = ({
    assetId,
    author,
    quote,
    sliderId,
    text,
    type,
  }: IHandleCreateSliderItemParams) => {
    void executeCreateSliderItem({
      variables: {
        sliderItem: {
          assetId,
          author,
          quote,
          sliderId,
          text,
          type,
        },
      },
    });
  };

  return {
    handleCreateSliderItem,
    isLoading,
  };
};

export { useCreateSliderItem };
