/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useState, useEffect } from 'react';

import { Carousel, ICarousel, CarouselItemProps } from '@netfront/ui-library';
import { IEditableSliderItem } from 'interfaces';


import { SliderPreviewProps } from './SliderPreview.interfaces';

const SliderPreview = ({
  getValues,
  isOpen = false,
}: SliderPreviewProps) => {
  const [slider, setSlider] = useState<ICarousel>();

  useEffect(() => {
    if (!isOpen) return;

    const {
      hasArrows = true, 
      isAutoplay = false,
      autoplaySpeed,
      hasDots = true,
      isInfinite = true,
      slidesToShow,
      speed,
      sliderItems = []
    } = getValues();

    setSlider({
      arrows: hasArrows,
      autoplay: isAutoplay,
      autoplaySpeed: Number(autoplaySpeed),
      dots: hasDots,
      infinite: isInfinite,
      sliderItems: (sliderItems as IEditableSliderItem[]).map((sliderItem, index) => ({
        imageSrc: sliderItem.presignedUrl,
        author: sliderItem.author,
        type: sliderItem.type,
        id: sliderItem.id || index,
        quote: sliderItem.quote,
        text: sliderItem.text,
      })) as CarouselItemProps[],
      slidesToScroll: sliderItems.length,
      slidesToShow,
      speed: Number(speed),
      id: 0,
      title: 'Slider preview',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, ]);


  return (
    <>
      {slider && (
        <Carousel slider={slider} />
      )}
    </>
  );
};

export { SliderPreview };
